import React from 'react';
import styled from 'styled-components';
import { ConfettiFramedContainer } from '@latitude/confetti-framed-container';
import Layout from '@/components/layout';
import Metadata from '@/components/Metadata/Metadata';
import EstimateRateWidget from '@/components/EstimateRateWidget/EstimateRateWidget';
import {SOFT_QUOTE_AU_URL} from '@/utils/constants';

export default ({ location }) => {
  return (
    <Layout location={location} noHeader noFooter>
      <style
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: `
            html, body, #___gatsby, #___gatsby > div {
              width: 100%; height: 100%; background-color: #F8F8F8;
            }
          `
        }}
      />
      <Metadata title="widget embed" robots="noindex" />
      <Outer>
        <Inner>
          <ConfettiFramedContainer css>
            <EstimateRateWidget
              getRate="https://test01.staging.quote-latitudefinancial.digitalweb-np.lfscnp.com/au/"
              main
            />
            <a href={SOFT_QUOTE_AU_URL} target="_top">
              navigate top frame
            </a>{' '}
            /{' '}
            <a href="https://google.com" target="_top">
              external link
            </a>
          </ConfettiFramedContainer>
        </Inner>
      </Outer>
    </Layout>
  );
};

const Outer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  align-items: center;
`;

const Inner = styled.div`
  width: 100%;
`;
